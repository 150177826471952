import React from "react"
import Title from "components/text/Title"
import Text from "components/text/Text"
import Button from "components/buttons/Button"
import Image from "components/images/Image"
import SemaglutideImage from "assets/images/Semaglutide.png"
import TirzepatideImage from "assets/images/Tirzepatide.png"
import { ReactComponent as CreditCardRefreshIcon } from "assets/images/credit-card-refresh.svg"
import InfoBox from "components/ui/InfoBox"
import { useGetSubscriptionQuery } from "app/services/subscriptionApi"
import { useNavigate, useParams } from "react-router-dom"
import { getFrequency } from "helpers/subscriptonHelpers"
import useSignInPopup from "features/auth/useSignInPopup"
import useAuth from "hooks/auth/useAuth"
import { DrugType } from "types/entities/drugTypes"
import SubscriptionLoader from "features/subscriptions/SubscriptionLoader"

const SubscriptionPage = () => {
  const DEFAULT_SLUG = "MmNkZm"
  const { slug } = useParams<{ slug: string }>()
  const navigate = useNavigate()
  const { showSignIn } = useSignInPopup()
  const user = useAuth()

  const { data: subscription, isLoading } = useGetSubscriptionQuery(
    slug ?? DEFAULT_SLUG,
  )

  if (isLoading) {
    return <SubscriptionLoader />
  }

  const handleSubscriptionClick = () => {
    if (user) {
      return navigate("/checkout", {
        state: {
          subscription: subscription,
        },
      })
    }

    return showSignIn({
      path: "/checkout",
      state: { subscription: subscription },
    })
  }

  const getDrugImage = (drugName: string | undefined) => {
    if (drugName === DrugType.SEMAGLUTIDE) {
      return SemaglutideImage
    }
    if (drugName === DrugType.TIRZEPATIDE) {
      return TirzepatideImage
    }
    return null
  }

  const drugName = subscription?.drug?.name
  const drugImage = getDrugImage(drugName)

  return (
    <>
      {isLoading ? (
        <SubscriptionLoader />
      ) : (
        <main className="flex flex-col items-center justify-center py-10 px-4">
          <div className="w-full max-w-6xl flex flex-col md:flex-row items-center md:items-start px-4 md:px-0 mx-auto">
            {drugImage ? (
              <Image src={drugImage} alt={`${drugName} Weight Loss Program`} />
            ) : null}

            <div className="w-full md:w-1/2 lg:w-2/3 md:ml-8 mt-6 md:mt-0">
              <Title family="header" weight="semibold">
                {subscription?.title}
              </Title>
              <InfoBox
                text="This product is available through a subscription"
                SvgIcon={CreditCardRefreshIcon}
                className="my-3"
              />
              <div className="flex items-center space-x-3 my-3">
                <Title family="header" size="s" weight="semibold">
                  ${subscription?.billing_cost}.00
                </Title>
                <Text family="body" color="grey" size="m">
                  {subscription ? "Billed " + getFrequency(subscription) : null}
                </Text>
              </div>

              <Button size="s" onClick={handleSubscriptionClick}>
                Start your subscription
              </Button>
            </div>
          </div>

          <div className="w-full max-w-6xl flex flex-col md:flex-row items-center md:items-start px-4 md:px-0 mx-auto my-8">
            <Text color="grey" size="l">
              {subscription?.description}
            </Text>
          </div>
        </main>
      )}
    </>
  )
}

export default SubscriptionPage
