import React from "react"
import Title from "components/text/Title"
import { Subscription } from "types/entities/subscriptionTypes"
import CheckoutContainer from "features/checkout/CheckoutContainer"
import { UserSubscription } from "types/entities/userSubscriptionTypes"

interface Props {
  subscription: Subscription
  userSubscription: UserSubscription | null
}

const Confirmation: React.FC<Props> = ({ subscription, userSubscription }) => {
  if (!userSubscription) return null

  return (
    <div className="w-full px-4">
      <div className="max-w-5xl mx-auto">
        <Title size="m">Thank you for your order!</Title>
        <Title size="xs">
          Your order has been received and we will begin processing it shortly.
        </Title>
        <div className="flex flex-col gap-6 w-full shadow-lg">
          <div className="bg-white rounded-md border border-gray-200 p-8 w-full">
            <div className="flex flex-col gap-6 w-full">
              <div className="border-b border-gray-200 py-4">
                <div className="flex items-center gap-4">
                  <div className="w-24 h-32 overflow-hidden">
                    <img
                      className="w-full h-full object-cover"
                      src="/images/semaglutide-default.png"
                      alt="Product"
                    />
                  </div>
                  <div className="flex-1 flex flex-col gap-2">
                    <div className="text-gray-900 font-semibold text-sm">
                      {subscription.title}
                    </div>
                    <div className="text-gray-700 text-sm">
                      Billed every {subscription.billingcustom} days for{" "}
                      {subscription.billing_period} months
                    </div>
                  </div>
                  <div className="text-gray-900 font-semibold text-sm text-right">
                    ${subscription.billing_cost}
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-4">
                <div className="flex justify-between border-b border-gray-200 py-3">
                  <div className="text-gray-900 font-medium text-md">
                    Shipping
                  </div>
                  <div className="text-gray-900 font-semibold text-md">
                    Free
                  </div>
                </div>
                <div className="flex justify-between border-b border-gray-200 py-3">
                  <div className="text-gray-900 font-medium text-md">
                    Subtotal
                  </div>
                  <div className="text-gray-900 font-semibold text-md">
                    ${subscription.billing_cost}
                  </div>
                </div>
                <div className="flex justify-between py-3">
                  <div className="text-gray-900 font-semibold text-lg">
                    Subscription cost
                  </div>
                  <div className="text-gray-900 font-semibold text-lg">
                    ${subscription.billing_cost}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-5">
        <CheckoutContainer>
          <div className="flex justify-between items-center">
            <div>
              <p className="text-gray-500">Next payment date</p>
              <p className="font-semibold text-lg">
                {new Date(userSubscription.next_payment_date).toDateString()}
              </p>
            </div>
            <div className="text-right">
              <p className="text-black font-semibold text-lg">
                ${subscription.billing_cost}
              </p>
            </div>
          </div>
        </CheckoutContainer>
      </div>
    </div>
  )
}

export default Confirmation
