import { ReactComponent as LogoSvg } from "assets/images/logo.svg"
import { ReactComponent as LogoutSvg } from "assets/images/logout.svg"
import { ReactComponent as ProfileSvg } from "assets/images/profile.svg"
import SignInPopup from "features/auth/SignInPopup"
import useLogout from "hooks/auth/useLogout"
import useAuth from "hooks/auth/useAuth"
import { Link } from "react-router-dom"
import useSignInPopup from "features/auth/useSignInPopup"

const BaseHeader = () => {
  const user = useAuth()
  const { showSignIn } = useSignInPopup()
  const logout = useLogout()

  return (
    <div className="shadow">
      <header className="container mx-auto px-4 py-4">
        <div className="flex justify-between items-center">
          <Link to="/">
            <LogoSvg />
          </Link>
          <div className="cursor-pointer">
            {user ? (
              <div className="grid grid-cols-2 gap-x-4">
                <div>
                  <Link to="/account">
                    <ProfileSvg />
                  </Link>
                </div>
                <div>
                  <LogoutSvg onClick={() => logout()} />
                </div>
              </div>
            ) : (
              <div>
                <ProfileSvg
                  onClick={() =>
                    showSignIn({
                      path: "/account",
                    })
                  }
                />
              </div>
            )}
          </div>
        </div>
      </header>
      <SignInPopup />
    </div>
  )
}

export default BaseHeader
