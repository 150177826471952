import React, {
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
  InputHTMLAttributes,
} from "react"
import { Libraries, useJsApiLoader } from "@react-google-maps/api"
import InputLabel from "components/inputs/InputLabel"
import { BaseFieldProps } from "types/core/formTypes"
import InputError from "components/inputs/InputError"

const libraries: Libraries = ["places"]

interface Props
  extends BaseFieldProps,
    Omit<InputHTMLAttributes<HTMLInputElement>, "onChange"> {
  onChange?: (value: google.maps.places.PlaceResult) => void
}

const GooglePlacesInput = forwardRef<HTMLInputElement, Props>(
  ({ label, error, placeholder, onChange, ...inputProps }, ref) => {
    const inputRef = useRef<HTMLInputElement | null>(null)

    const { isLoaded, loadError } = useJsApiLoader({
      googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
      libraries,
    })
    useImperativeHandle(ref, () => inputRef.current!, [])

    useEffect(() => {
      if (isLoaded && inputRef.current) {
        const autocomplete = new google.maps.places.Autocomplete(
          inputRef.current,
          {
            types: ["address"],
          },
        )

        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace()
          if (!onChange) {
            return
          }

          onChange(place)
        })

        return () => {
          // Cleanup to prevent memory leaks
          google.maps.event.clearInstanceListeners(autocomplete)
        }
      }
    }, [isLoaded, onChange])

    if (loadError) {
      return <div>Error loading Google Maps API</div>
    }

    return (
      <div>
        <InputLabel>{label}</InputLabel>
        <input
          ref={inputRef}
          type="text"
          placeholder={placeholder}
          {...inputProps}
          className="block w-full p-4 border border-silver rounded-md"
        />
        <InputError>{error}</InputError>
      </div>
    )
  },
)

export default GooglePlacesInput
