import { ButtonHTMLAttributes, FC, ReactNode } from "react"

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode
  color?: "black" | "white"
  size?: "s" | "m" | "l"
  fullWidth?: boolean
}

const colorClasses: Record<string, string> = {
  black: "text-white bg-black",
  white: "text-black bg-white",
}

const sizeClasses: Record<string, string> = {
  s: "font-medium px-10 py-3 text-base",
  m: "font-medium px-10 py-4 text-lg",
  l: "font-medium px-10 py-5 text-2xl",
}

const Button: FC<Props> = ({
  children,
  color = "black",
  size = "m",
  fullWidth = true,
  ...buttonProps
}) => {
  const colorClass = colorClasses[color]
  const sizeClass = sizeClasses[size]
  const fullWidthClass = fullWidth ? "w-full" : ""

  return (
    <button
      type="button"
      className={`whitespace-nowrap rounded-md border-2 border-black disabled:opacity-20 ${colorClass} ${sizeClass} ${fullWidthClass}`}
      {...buttonProps}
    >
      {children}
    </button>
  )
}

export default Button
