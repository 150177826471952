import * as yup from "yup"

export const PHONE_REGEX = /^\d{10}$/
export const PHONE_REGEX_WITH_CODE = /^\+1\d{10}$/

export const PHONE_RULES = yup
  .string()
  .matches(PHONE_REGEX, "This phone number isn't valid")

export const PHONE_WITH_CODE_RULES = yup
  .string()
  .matches(
    PHONE_REGEX_WITH_CODE,
    "This phone number isn't valid. Please use format +11234567890.",
  )

export const EMAIL_RULES = yup.string().email("Invalid email")
