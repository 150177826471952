import React from "react"
import { Subscription } from "types/entities/subscriptionTypes"

interface Props {
  subscription: Subscription
}

const ReviewSubscriptionSummary: React.FC<Props> = ({ subscription }) => {
  return (
    <div>
      <div className="border-b border-gray-200 py-4">
        <div className="flex items-center gap-4">
          <div className="w-24 h-32 overflow-hidden">
            <img
              className="w-full h-full object-cover"
              src="/images/semaglutide-default.png"
              alt="Product"
            />
          </div>
          <div className="flex-1 flex flex-col gap-2">
            <div className="text-gray-900 font-semibold text-sm">
              {subscription.title}
            </div>
            <div className="text-gray-700 text-sm">
              Billed every {subscription.billingcustom} days for 6 months
            </div>
          </div>
          <div className="text-gray-900 font-semibold text-sm text-right">
            ${subscription.billing_cost}
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex justify-between border-b border-gray-200 py-3">
          <div className="text-gray-900 font-medium text-md">Shipping</div>
          <div className="text-gray-900 font-semibold text-md">Free</div>
        </div>
        <div className="flex justify-between border-b border-gray-200 py-3">
          <div className="text-gray-900 font-medium text-md">Subtotal</div>
          <div className="text-gray-900 font-semibold text-md">
            ${subscription.billing_cost}
          </div>
        </div>
        <div className="flex justify-between py-3">
          <div className="text-gray-900 font-semibold text-lg">
            Subscription cost
          </div>
          <div className="text-gray-900 font-semibold text-lg">
            ${subscription.billing_cost}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReviewSubscriptionSummary
