import { api } from "app/services/api"
import { Subscription } from "types/entities/subscriptionTypes"
import { CheckoutData } from "features/checkout/CheckoutSteps"
import { UserSubscription } from "types/entities/userSubscriptionTypes"

export const subscriptionApi = api.injectEndpoints({
  endpoints: (build) => {
    return {
      getSubscription: build.query<Subscription, string>({
        query: (slug) => `subscriptions/${slug}`,
      }),
      checkoutSubscription: build.mutation<
        UserSubscription,
        { id: string; checkoutData: CheckoutData }
      >({
        query: ({ id, checkoutData }) => {
          // Convert dateOfBirth to ISO string if it exists
          if (checkoutData.accountInfo.dateOfBirth) {
            checkoutData.accountInfo.dateOfBirth = new Date(
              checkoutData.accountInfo.dateOfBirth,
            ).toISOString()
          }

          return {
            url: `/subscriptions/${id}/checkout`,
            method: "PUT",
            body: JSON.stringify(checkoutData),
            headers: {
              "content-type": "application/ld+json",
            },
          }
        },
        invalidatesTags: ["Account"],
      }),
    }
  },
})

export const { useGetSubscriptionQuery, useCheckoutSubscriptionMutation } =
  subscriptionApi
