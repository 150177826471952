import React, { useState } from "react"
import Title from "components/text/Title"
import CheckoutContainer from "features/checkout/CheckoutContainer"
import ReviewAccountInformation from "features/checkout/steps/review/ReviewAccoutInfo"
import ReviewSubscriptionSummary from "features/checkout/steps/review/ReviewSubscriptionSummary"
import ReviewShippingInfo from "features/checkout/steps/review/ReviewShippingInfo"
import ReviewPaymentInfo from "features/checkout/steps/review/ReviewPaymentInfo"
import ReviewConfirmationCheckbox from "features/checkout/steps/review/ReviewConfirmationCheckbox"
import Button from "components/buttons/Button"
import {
  CheckoutData,
  CheckoutStep,
  CheckoutDataKey,
} from "features/checkout/CheckoutSteps"
import { Subscription } from "types/entities/subscriptionTypes"
import { useCheckoutSubscriptionMutation } from "app/services/subscriptionApi"
import { UserSubscription } from "types/entities/userSubscriptionTypes"
import Alert from "components/notifications/Alert"

interface Props {
  onSubmit: (userSubscription: UserSubscription) => void
  initialCheckoutData: CheckoutData
  subscription: Subscription
}

const Review: React.FC<Props> = ({
  onSubmit,
  initialCheckoutData,
  subscription,
}) => {
  const [checkoutData, setCheckoutData] =
    useState<CheckoutData>(initialCheckoutData)
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [checkoutSubscription, { isLoading, isError }] =
    useCheckoutSubscriptionMutation()

  const handleCheckoutData = (step: CheckoutStep, data: object) => {
    const stepData: CheckoutDataKey = {}
    stepData[step] = data

    setCheckoutData((prevData) => ({
      ...prevData,
      ...stepData,
    }))
  }

  const handleCheckboxChange = (isChecked: boolean) => {
    setIsConfirmed(isChecked)
  }

  const handleSubmit = async () => {
    try {
      const userSubscription = await checkoutSubscription({
        id: subscription.id.toString(),
        checkoutData: checkoutData,
      }).unwrap()

      onSubmit(userSubscription)
    } catch (error) {
      // Handle error
    }
  }

  return (
    <div className="w-full px-4">
      <div className="max-w-5xl mx-auto">
        <Title size="s">Review order</Title>

        <CheckoutContainer>
          <ReviewSubscriptionSummary subscription={subscription} />
        </CheckoutContainer>

        <div className="mt-5">
          <CheckoutContainer>
            <ReviewAccountInformation
              checkoutData={checkoutData}
              onSubmit={(data) => {
                handleCheckoutData(CheckoutStep.ACCOUNT_INFO, data)
              }}
            />
          </CheckoutContainer>
        </div>

        <div className="mt-5">
          <CheckoutContainer>
            <ReviewShippingInfo
              checkoutData={checkoutData}
              onSubmit={(data) => {
                handleCheckoutData(CheckoutStep.SHIPPING_INFO, data)
              }}
            />
          </CheckoutContainer>
        </div>

        <div className="mt-5">
          <CheckoutContainer>
            <ReviewPaymentInfo
              checkoutData={checkoutData}
              onSubmit={(data) => {
                handleCheckoutData(CheckoutStep.PAYMENT_INFO, data)
              }}
            />
          </CheckoutContainer>
        </div>
      </div>
      <div className="mt-5">
        <ReviewConfirmationCheckbox
          onCheckChange={handleCheckboxChange}
          subscription={subscription}
          checkoutData={checkoutData}
        />
      </div>
      {isError && <Alert className="mt-2">Payment failed.</Alert>}
      <div className="mt-5">
        <Button
          size="s"
          onClick={handleSubmit}
          disabled={!isConfirmed || isLoading}
        >
          Complete subscription enrollment
        </Button>
      </div>
    </div>
  )
}

export default Review
