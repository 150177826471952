import FinixPaymentForm from "features/finix/FinixPaymentForm"
import React from "react"
import {
  CheckoutData,
  CheckoutPaymentInfo,
  CheckoutStep,
} from "features/checkout/CheckoutSteps"

interface Props {
  checkoutData: CheckoutData
  onSubmit: (data: CheckoutPaymentInfo) => void
  buttonLabel: string
}

const PaymentForm: React.FC<Props> = ({
  checkoutData,
  onSubmit,
  buttonLabel,
}) => {
  const accountInfo = checkoutData[CheckoutStep.ACCOUNT_INFO]
  const shippingInfo = checkoutData[CheckoutStep.SHIPPING_INFO]

  return (
    <FinixPaymentForm
      onSuccess={({ paymentInstrument, fraudSessionId, idempotencyId }) =>
        onSubmit({
          finixToken: paymentInstrument.id,
          last4: paymentInstrument.card_details.last_four,
          cardType: paymentInstrument.card_details.brand,
          fraudSessionId: fraudSessionId,
          idempotencyId: idempotencyId,
        })
      }
      buttonLabel={buttonLabel}
      identity={{
        firstName: accountInfo.firstName,
        lastName: accountInfo.lastName,
        email: accountInfo.email,
        phone: accountInfo.phone,
        personalAddress: {
          line1: shippingInfo.streetAddress,
          line2: shippingInfo.apt,
          city: shippingInfo.city,
          region: shippingInfo.state,
          postalCode: shippingInfo.zipCode,
        },
      }}
    />
  )
}

export default PaymentForm
