import useAuth from "hooks/auth/useAuth"
import { useAppDispatch } from "app/hooks"
import { getAccessToken, getMe, setMe } from "helpers/authHelpers"
import { useGetAccountQuery } from "app/services"
import useLogout from "hooks/auth/useLogout"
import { useEffect } from "react"
import { setUser } from "features/auth/authSlice"

const useRefreshAuth = () => {
  const user = useAuth()
  const dispatch = useAppDispatch()
  const storageUser = getMe()
  const { data, isError } = useGetAccountQuery()
  const logout = useLogout()

  useEffect(() => {
    if (isError) {
      logout()
    }
  }, [isError])

  useEffect(() => {
    if (data) {
      setMe(data)
      dispatch(setUser(data))
    }
  }, [data])

  if (getAccessToken() && storageUser && !user) {
    dispatch(setUser(storageUser))
  }
}

export default useRefreshAuth
