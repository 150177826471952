import Popup from "components/popups/Popup"
import { useEffect, useState } from "react"
import SendWellnessCode from "features/auth/SendWellnessCode"
import InputWellnessCode from "features/auth/InputWellnessCode"
import useLogin from "hooks/auth/useLogin"
import useSignInPopup from "features/auth/useSignInPopup"
import { User } from "types/entities/userTypes"
import PoweredBy from "components/blocks/PoweredBy"
import { selectRedirectAfterLogin } from "features/auth/authSlice"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

const SignInPopup = () => {
  const { hideSignIn, isSignInOpen } = useSignInPopup()
  const navigate = useNavigate()
  const [userId, setUserId] = useState<string>()
  const [contact, setContact] = useState<string>()
  const login = useLogin()
  const redirectAfterLogin = useSelector(selectRedirectAfterLogin)

  const cancel = () => {
    setUserId(undefined)
    setContact(undefined)
  }

  const onSignIn = (token: string, user: User) => {
    if (userId) {
      login(token, user)

      if (redirectAfterLogin) {
        navigate(redirectAfterLogin.path, { state: redirectAfterLogin.state })
      }

      cancel()
      hideSignIn()
    }
  }

  useEffect(() => {
    if (!isSignInOpen) {
      cancel()
    }
  }, [isSignInOpen])

  return (
    <Popup isOpen={isSignInOpen} onClose={() => hideSignIn()}>
      <div className="md:w-[450px]">
        {!userId ? (
          <SendWellnessCode setUserId={setUserId} setContact={setContact} />
        ) : (
          <InputWellnessCode
            contact={contact}
            userId={userId}
            onSuccess={onSignIn}
            cancel={cancel}
          />
        )}
        <PoweredBy />
      </div>
    </Popup>
  )
}

export default SignInPopup
