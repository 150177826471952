import Title from "components/text/Title"
import TextInput from "components/inputs/TextInput"
import useSendWellnessCode from "features/auth/useSendWellnessCode"
import { Dispatch, FC, SetStateAction } from "react"
import { Link } from "react-router-dom"
import Button from "components/buttons/Button"

interface Props {
  setUserId: Dispatch<SetStateAction<string | undefined>>
  setContact: Dispatch<SetStateAction<string | undefined>>
}

const SendWellnessCode: FC<Props> = ({ setUserId, setContact }) => {
  const { handleInputChange, handleSumbit, isButtonDisabled, error } =
    useSendWellnessCode({ setUserId, setContact })

  return (
    <>
      <Title size="m" family="header">
        Sign In to Your Account
      </Title>
      <div className="text-2xl mt-6 font-body">
        Enter your mobile or email to continue.
      </div>
      <div className="mt-1 font-body">
        If you don’t have an account, we’ll create one for you.
      </div>
      <div className="mt-6">
        <TextInput
          placeholder="Mobile or email"
          onChange={handleInputChange}
          error={error}
        />
      </div>
      <div className="mt-6 font-body">
        By clicking ‘Get Started’, you agree to DrWell’s{" "}
        <Link to="/terms-of-use" target="_blank" rel="noopener noreferrer">
          <b>Terms of Service</b>
        </Link>{" "}
        and{" "}
        <Link to="/privacy-policy" target="_blank" rel="noopener noreferrer">
          <b>Privacy Policy</b>
        </Link>
        .
      </div>
      <div className="mt-6">
        <Button disabled={isButtonDisabled} onClick={handleSumbit}>
          Get Started
        </Button>
      </div>
    </>
  )
}

export default SendWellnessCode
