import React, { useState } from "react"
import { Subscription } from "types/entities/subscriptionTypes"
import { getFrequency } from "helpers/subscriptonHelpers"
import { CheckoutData } from "features/checkout/CheckoutSteps"

interface Props {
  onCheckChange: (isChecked: boolean) => void
  subscription: Subscription
  checkoutData: CheckoutData
}

const ReviewConfirmationCheckbox: React.FC<Props> = ({
  onCheckChange,
  subscription,
  checkoutData,
}) => {
  const [checked, setChecked] = useState(false)

  const handleCheckboxChange = () => {
    const newChecked = !checked

    setChecked(newChecked)
    onCheckChange(newChecked)
  }

  return (
    <label className="flex flex-row gap-4 items-center cursor-pointer">
      <input
        type="checkbox"
        checked={checked}
        onChange={handleCheckboxChange}
        className="hidden"
      />
      <div
        className={`w-5 h-5 flex items-center justify-center border rounded ${
          checked ? "bg-black border-black" : "bg-white border-gray-300"
        }`}
        onClick={handleCheckboxChange}
      >
        {checked && (
          <svg
            className="w-3.5 h-3.5"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.6668 3.5L5.25016 9.91667L2.3335 7"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </div>
      <span className="text-colors-text-text-secondary-700 text-left font-text-md-medium-font-family text-text-md-medium-font-size leading-text-md-medium-line-height font-text-md-medium-font-weight relative self-stretch">
        I confirm that my order details are accurate and authorize DrWell to{" "}
        charge my {checkoutData.paymentInfo.cardType} ending in{" "}
        {checkoutData.paymentInfo.last4}{" "}
        {getFrequency(subscription)?.toLowerCase()} for the next{" "}
        {subscription.billing_period} months.
      </span>
    </label>
  )
}

export default ReviewConfirmationCheckbox
