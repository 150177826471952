import { FormFieldI } from "types/core/formTypes"
import { FormFieldType } from "constants/core/formConstants"
import * as yup from "yup"
import { buildYupSchema } from "helpers/core/formHelpers"

export const detailsFields: FormFieldI[] = [
  {
    type: FormFieldType.TEXT,
    name: "name_first",
    label: "First name",
    placeholder: "First name",
    rules: yup.string().required(),
  },
  {
    type: FormFieldType.TEXT,
    name: "name_last",
    label: "Last name",
    placeholder: "Last name",
    rules: yup.string().required(),
  },
  {
    type: FormFieldType.DATE,
    name: "birth_date",
    label: "Date of birth",
    rules: yup.date().required(),
  },
  {
    type: FormFieldType.SELECT,
    name: "gender_assigned_at_birth",
    label: "Gender",
    placeholder: "Gender",
    rules: yup.string().required(),
    options: [
      {
        value: "male",
        label: "Male",
      },
      {
        value: "female",
        label: "Female",
      },
    ],
  },
]

export const detailsYupSchema = buildYupSchema(detailsFields)

export const communicationFields: FormFieldI[] = [
  {
    type: FormFieldType.CHECKBOX,
    name: "mobile_marketing_enabled",
    label: "It’s okay to send me marketing texts",
  },
  {
    type: FormFieldType.CHECKBOX,
    name: "mobile_care_enabled",
    label: "It’s okay to send me texts related to my care",
  },
  {
    type: FormFieldType.CHECKBOX,
    name: "email_marketing_enabled",
    label: "It’s okay to send me texts related to my care",
  },
  {
    type: FormFieldType.CHECKBOX,
    name: "email_care_enabled",
    label: "It’s okay to send me texts related to my care",
  },
]

export const communicationYupSchema = buildYupSchema(communicationFields)
