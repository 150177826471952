import {
  Subscription,
  SubscriptionType,
} from "types/entities/subscriptionTypes"

export const getFrequency = (subscription: Subscription) => {
  switch (subscription.billing_period || "monthly") {
    case "custom":
      return `Every ${subscription.billingcustom} days`
    case "monthly":
      return "Every month"
    case "quarterly":
      return "Every quarter"
    case "6-month":
      return "Every 6 months"
    case "yearly":
      return "Every year"
  }
}
export const replaceTypeInText = (text: string, type: SubscriptionType) => {
  const typeText = getTypeName(type)

  return text.replace("{type}", typeText)
}

export const getTypeName = (type: SubscriptionType) => {
  return type === SubscriptionType.SUBSCRIPTION ? "subscription" : "membership"
}
