import Button from "components/buttons/Button"
import TextButton from "components/buttons/TextButton"
import { Link } from "react-router-dom"

const PaymentMethodList = () => {
  const isEmpty = true

  return (
    <div className="pt-8">
      {!isEmpty ? (
        <>
          <div className="text-center text-lg mb-6">
            You have no payment methods.
          </div>
          <div className="flex justify-center">
            <Button size="m" fullWidth={false}>
              Add payment method
            </Button>
          </div>
        </>
      ) : (
        <div className="flex justify-between p-8 border rounded-xl text-lg">
          <div>Mastercard ending in 1234</div>
          <div>
            <Link to="/account/payment-methods/1">
              <TextButton icon="edit">Edit</TextButton>
            </Link>
          </div>
        </div>
      )}
    </div>
  )
}

export default PaymentMethodList
