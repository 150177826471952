import Popup from "components/popups/Popup"
import { FC, useState } from "react"
import ManageSubscriptionMainBlock from "features/account/subscriptions/ManageSubscriptionMainBlock"
import ManageSubscriptionCancelBlock from "features/account/subscriptions/ManageSubscriptionCancelBlock"
import ManageSubscriptionDisableAutoPayBlock from "features/account/subscriptions/ManageSubscriptionDisableAutoPayBlock"
import ManageSubscriptionPaymentBlock from "features/account/subscriptions/ManageSubscriptionPaymentBlock"
import ManageSubscriptionPaymentCompleteBlock from "features/account/subscriptions/ManageSubscriptionPaymentCompleteBlock"
import { UserSubscription } from "types/entities/userSubscriptionTypes"
import { replaceTypeInText } from "helpers/subscriptonHelpers"

interface Props {
  onClose: () => void
  sub: UserSubscription
}

interface BT {
  [key: string]: string
}

const blockTitles: BT = {
  manage: "Manage {type}",
  payment: "Update payment details",
  paymentComplete: "Payment complete",
  cancel: "Cancel {type}",
  disableAutoPay: "Disable auto-pay",
}

const ManageSubscriptionPopup: FC<Props> = ({ sub, onClose }) => {
  const [block, setBlock] = useState<string>("manage")

  const buildBLock = () => {
    switch (block) {
      case "manage":
        return (
          <ManageSubscriptionMainBlock
            sub={sub}
            onUpdatePaymentClick={() => setBlock("payment")}
            onCancelClick={() => setBlock("cancel")}
            onDisableAutoPayClick={() => setBlock("disableAutoPay")}
          />
        )
      case "payment":
        return (
          <ManageSubscriptionPaymentBlock
            sub={sub}
            onUpdate={() => setBlock("manage")}
            onClose={() => setBlock("manage")}
          />
        )
      case "paymentComplete":
        return <ManageSubscriptionPaymentCompleteBlock />
      case "cancel":
        return (
          <ManageSubscriptionCancelBlock
            sub={sub}
            onCancel={() => setBlock("manage")}
            onClose={() => setBlock("manage")}
          />
        )
      case "disableAutoPay":
        return (
          <ManageSubscriptionDisableAutoPayBlock
            sub={sub}
            onDisable={() => setBlock("manage")}
            onClose={() => setBlock("manage")}
          />
        )
    }
  }

  return (
    <Popup isOpen={true} onClose={onClose} verticalCenter={false}>
      <div className="md:w-[680px] -mt-10 text-left">
        <div className="text-3xl font-semibold mb-1">
          {replaceTypeInText(blockTitles[block], sub.subscription.sub_type)}
        </div>
        {buildBLock()}
      </div>
    </Popup>
  )
}

export default ManageSubscriptionPopup
