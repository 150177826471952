import BaseLayout from "layouts/BaseLayout"
import Checkout from "features/checkout/Checkout"

const SubscriptionCheckout = () => {
  return (
    <BaseLayout hasBreadcrumbs={false} maxWidth="m">
      <Checkout />
    </BaseLayout>
  )
}

export default SubscriptionCheckout
