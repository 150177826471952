import { FC } from "react"
import { ReactComponent as CheckedSvg } from "assets/images/checked-large.svg"
import { ReactComponent as RepeatSvg } from "assets/images/repete-bordered.svg"
import Title from "components/text/Title"
import Box from "components/blocks/Box"
import TextButton from "components/buttons/TextButton"

interface Props {}

const ManageSubscriptionPaymentCompleteBlock: FC<Props> = ({}) => {
  return (
    <>
      <div className="mt-12 text-center">
        <CheckedSvg className="inline-block" />
        <Title className="mt-8" size="m">
          Your payment was made successfully.
        </Title>
        <div className="font-semibold text-sm mt-4">THANK YOU</div>
        <div className="flex justify-center mt-20">
          <Box className="relative pt-12 pb-4 max-w-[420px] flex-grow">
            <RepeatSvg className="absolute left-1/2 -top-8 -translate-x-1/2" />
            <div className="mb-2">
              Your next payment will be automatically charged on
            </div>
            <div>
              <b>September 1, 2024.</b>
            </div>
          </Box>
        </div>
        <TextButton className="mt-8 mb-12">Close</TextButton>
      </div>
    </>
  )
}

export default ManageSubscriptionPaymentCompleteBlock
