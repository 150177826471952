import { format as dateFnsFormat } from "date-fns"

export const DATE_FORMAT_DEFAULT = "MM/dd/yyyy"
export const DATE_FORMAT_FULL_MONTH = "LLL dd, yyyy"

export const formatDate = (
  date: Date | string | null | undefined,
  format: string = DATE_FORMAT_DEFAULT,
): string | null => {
  if (!date) {
    return null
  }

  return dateFnsFormat(date, format)
}
