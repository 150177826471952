import { UserSubscription } from "types/entities/userSubscriptionTypes"
import { activeEnrollmentStatuses } from "constants/userSubscriptionConstants"
import { Transaction, TransactionStatus } from "types/entities/transactionTypes"
import { filterBySubscriptionId } from "helpers/transactionsHelpers"

export const isSubscriptionActive = (subscription: UserSubscription) => {
  return activeEnrollmentStatuses.includes(subscription.enrollment_status)
}

export const isPaymentFailed = (
  subId: number | string,
  items: Transaction[],
) => {
  const subTransactions = filterBySubscriptionId(subId, items)

  return (
    subTransactions[0] && subTransactions[0].ack === TransactionStatus.FAILURE
  )
}
