import { FC } from "react"
import { Control, useController } from "react-hook-form"
import { FormFieldI } from "types/core/formTypes"
import { FORM_FIELD_COMPONENTS } from "constants/core/formConstants"

interface Props {
  field: FormFieldI
  control: Control
}

const FormField: FC<Props> = ({ field, control }) => {
  const Component = FORM_FIELD_COMPONENTS[field.type]
  const {
    field: { onChange: controlOnChange, ...controlField },
    fieldState: { error },
  } = useController({ name: field.name, control })

  const onChange = (value: any) => {
    if (field.onChange) {
      field.onChange(value)
    } else {
      controlOnChange(value)
    }
  }

  return (
    <div className="mt-8">
      <Component
        {...controlField}
        onChange={onChange}
        error={error?.message}
        label={field.label}
        placeholder={field.placeholder}
        options={field.options}
      />
    </div>
  )
}

export default FormField
