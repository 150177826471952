import BaseLayout from "layouts/BaseLayout"
import { useParams } from "react-router-dom"
import PaymentMethodEdit from "features/account/payment-methods/PaymentMethodEdit"

const AccountPaymentMethodEdit = () => {
  const { id } = useParams<{ id: string }>()

  if (!id) {
    return null
  }

  return (
    <BaseLayout title="Payment methods" maxWidth="max-w-xl">
      <PaymentMethodEdit id={id} />
    </BaseLayout>
  )
}

export default AccountPaymentMethodEdit
