import React, { FC } from "react"
import FinixPaymentForm from "features/finix/FinixPaymentForm"
import Title from "components/text/Title"
import Button from "components/buttons/Button"
import { Link } from "react-router-dom"

interface Props {
  id: string
}

const PaymentMethodEdit: FC<Props> = ({ id }) => {
  return (
    <div className="pt-8">
      <Title className="mb-8">Edit card details</Title>
      <FinixPaymentForm onSuccess={() => {}} buttonLabel="Save changes" />
      <div className="mt-5">
        <Link to="/account/payment-methods">
          <Button size="s" color="white">
            Cancel
          </Button>
        </Link>
      </div>
    </div>
  )
}

export default PaymentMethodEdit
