import { ClipboardEvent, useEffect, useRef, useState } from "react"

interface Props {
  onSubmit: (value: string) => void
}

const useWellnessCodeForm = ({ onSubmit }: Props) => {
  const [values, setValues] = useState(["", "", "", "", "", ""])
  const value = values.join("")
  const inputRefs = useRef<HTMLInputElement[]>([])

  useEffect(() => {
    inputRefs.current[0].focus()
  }, [])

  const handlePaste = (e: ClipboardEvent<any>) => {
    const pasted = e.clipboardData.getData("text/plain")
    setValues(pasted.split("").slice(0, values.length))
  }

  const handleInputChange = (index: number, val: string) => {
    setValues((prev) => {
      const updated = [...prev]
      updated[index] = val
      return updated
    })

    if (index < 5) {
      inputRefs.current[index + 1].focus()
    }
  }

  const handleSubmit = () => {
    onSubmit(value)
  }

  return {
    handleInputChange,
    handlePaste,
    isSubmitDisabled: value.length < 6,
    values,
    handleSubmit,
    inputRefs,
  }
}

export default useWellnessCodeForm
