import React, { FC } from "react"
import FinixPaymentForm from "features/finix/FinixPaymentForm"
import { UserSubscription } from "types/entities/userSubscriptionTypes"
import Button from "components/buttons/Button"

interface Props {
  sub: UserSubscription
  onUpdate: () => void
  onClose: () => void
}

const ManageSubscriptionPaymentBlock: FC<Props> = ({
  sub,
  onUpdate,
  onClose,
}) => {
  return (
    <div className="mt-8">
      <div className="mb-8">
        Please update your payment information to reactivate your subscription.
      </div>
      <FinixPaymentForm
        onSuccess={onUpdate}
        buttonLabel="Update payment & process"
      />
      <div className="mt-5">
        <Button size="s" color="white" onClick={onClose}>
          Cancel
        </Button>
      </div>
    </div>
  )
}

export default ManageSubscriptionPaymentBlock
