import BaseFooter from "layouts/BaseFooter"
import BaseHeader from "layouts/BaseHeader"
import React, { useEffect, useState } from "react"
import TextBlockLoader from "components/loaders/TextBlockLoader"

const PrivacyPolicy = () => {
  const [content, setContent] = useState("")

  useEffect(() => {
    fetch("/policies/PrivacyPolicy.html")
      .then((response) => response.text())
      .then((html) => setContent(html))
  }, [])

  return (
    <div>
      <BaseHeader />
      {content ? (
        <div className="flex justify-center mt-4">
          <iframe
            srcDoc={content}
            title="Privacy Policy"
            style={{
              width: "100%",
              maxWidth: "800px",
              height: "600px",
              border: "none",
              boxShadow: "none",
            }}
          />
        </div>
      ) : (
        <TextBlockLoader />
      )}
      <BaseFooter />
    </div>
  )
}

export default PrivacyPolicy
