import React from "react"
import BaseFooter from "layouts/BaseFooter"
import BaseHeader from "layouts/BaseHeader"
import SubscriptionPage from "features/subscriptions/SubscriptionPage"

const Subscription = () => {
  return (
    <>
      <BaseHeader />
      <SubscriptionPage />
      <BaseFooter />
    </>
  )
}

export default Subscription
