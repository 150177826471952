import React from "react"
import ContentLoader from "react-content-loader"

const TextBlockLoader = () => (
  <ContentLoader
    speed={2}
    width={800}
    height={600}
    viewBox="0 0 800 600"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="0" rx="3" ry="3" width="800" height="20" />
    <rect x="0" y="40" rx="3" ry="3" width="800" height="20" />
    <rect x="0" y="80" rx="3" ry="3" width="800" height="20" />
    <rect x="0" y="120" rx="3" ry="3" width="800" height="20" />
    <rect x="0" y="160" rx="3" ry="3" width="800" height="20" />
    <rect x="0" y="200" rx="3" ry="3" width="800" height="20" />
    <rect x="0" y="240" rx="3" ry="3" width="800" height="20" />
  </ContentLoader>
)

export default TextBlockLoader
